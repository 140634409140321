// Here you can add other styles

.drop-down-icon {
  width: 18px;
}

.cal-highlight {
  //border: 1px solid #d10000 !important;
  font-weight: bold;
  color: #064a00;

  .fa-circle {
    color: #17860e;
    display: block;
    position: relative;
    left: 10px;
    margin-top: 4px;
  }
}

.cal-no-highlight {
  .fa-circle {
    display: block;
    color: rgba(0, 0, 0, 0);
    margin-top: 4px;
  }
}

.date_in_list {
  font-size: 18pt;
  text-align: center;
  font-weight: bold;
  margin-top: 4px;
  letter-spacing: 2pt;

  span {
    font-size: 10pt;
    display: block;
    font-weight: normal;
    letter-spacing: 0;
    margin-top: -5px;
  }
}

.distance_in_list {
  font-size: 12pt;
  margin-top: 18px;
}

.react-calendar__month-view__days__day--weekend {
  color: black !important;
}

.c-sidebar-minimizer {
  position: relative !important;
}

.c-header {
  min-height: 85px;
}

@include media-breakpoint-up(lg) {
  .c-app:not(.c-legacy-theme):not(.c-dark-theme)
    .c-sidebar.c-sidebar-lg-show.c-sidebar-fixed,
  .c-app:not(.c-legacy-theme):not(.c-dark-theme)
    .c-sidebar.c-sidebar-show.c-sidebar-fixed {
    box-shadow: none;
  }

  .c-app:not(.c-legacy-theme):not(.c-dark-theme)
    .c-sidebar.c-sidebar-lg-show.c-sidebar-unfoldable,
  .c-app:not(.c-legacy-theme):not(.c-dark-theme)
    .c-sidebar.c-sidebar-show.c-sidebar-unfoldable {
    box-shadow: none;
  }

  .c-sidebar .c-sidebar-brand {
    background: #fff;
  }

  .c-sidebar-minimized .c-sidebar-brand-minimized {
    margin: 18px 0;
  }

  .c-sidebar .c-sidebar-nav-link:hover,
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    background-color: theme-color("primary");
  }

  /*.c-sidebar-unfoldable:not(:hover).c-sidebar-fixed {
  width: 135px;
  }

  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-left: 135px;
  }*/
}

.login-content {
  background: linear-gradient(to right, #122230 0%, rgb(9, 17, 24) 100%);
  width: 100%;
  height: 100%;
  display: flex;
}

.login-right {
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-left {
  color: white;
  line-height: 1.6em;
  font-size: 1.6em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12rem;

  p {
    font-size: 0.7em;
    width: 70%;
  }
}

.login-form {
  width: 80%;

  .create-reset-box {
    margin: 20px 0 20px 0;
    display: flex;

    div {
      cursor: pointer;      
      padding-left: 10px;
    }

    div:first-of-type {
      padding-left: 0px;
      padding-right: 10px;
    }

    div:hover {
      text-decoration: underline;
    }
    
  }
}

.ql-editor {
  min-height: 100px;
}

.page-header {
  border-bottom: 2px solid theme-color("color");
  padding-bottom: 10px;
  margin-bottom: 22px;

  &.with_search {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.partner_profile_name {
  font-size: 3em;
  font-weight: bold;
  color: theme-color("color");
  margin: 12px;
}

.social_link_bar svg {
  color: #5ccbfb;
  font-size: 25pt;
  margin: 0 4px 10px 6px;
  display: inline-block;
  cursor: pointer;
}

.social_link_bar svg:hover {
  color: #009cdf;
}

.partner_trophy {
  font-size: 82px;
  margin: 14px 0 0 0;
}

.leaflet-container {
  height: 400px;
  width: 100%;
}

// calendar/scheduler styles
.rbc-calendar {
  min-height: 75vh;
}

.rbc-toolbar .rbc-toolbar-label {
  font-size: 22px;
}

.quill.is-invalid,
.multi.is-invalid {
  border: 1px solid #e55353;
}

.quill.is-valid,
.multi.is_valid {
  border: 1px solid #2eb85c;
}

.opportunity_search_result {
  margin-bottom: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  padding-bottom: 16px;
}

.search_opportunity_title {
  font-size: 24px;
  font-weight: bold;
  text-shadow: 1px 1px white;
}

.search_opportunity_partner {
  font-size: 16px;
  color: #666;
  margin-top: -9px;
}

.search_short_description {
  font-size: 12px;
  color: #666;
}

.content_group {
  margin-bottom: 15px;
}

.mini-header {
  padding: 8px 8px 8px 0;
  color: #555;
  font-weight: bold;
  font-size: 16px;

  svg {
    font-size: 14pt;
    margin-right: 8px;
    color: #999;
  }
}

.register_timeslot {
  width: 150px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #f9f9f9;
  text-align: center;
  padding: 12px;
  float: left;
  min-height: 90px;
  margin: 18px;
  font-size: 8pt;
  cursor: pointer;
}

.register_timeslot_date {
  font-size: 14pt;
  font-weight: bold;
  margin-bottom: 5px;
}

.register_availability {
  margin-top: 10px;
}

.timeslot_disabled {
  color: #bbb;
  cursor: default !important;
}

.student_projects {
  margin-bottom: 5px;

  .project_date {
    color: #999;
    text-align: center;
    font-size: 10pt;
    margin-top: 10px;

    .month {
      font-weight: bold;
      font-size: 12pt;
      display: block;
    }
  }

  .project_title {
    font-size: 20pt;
    color: #428bca;
    display: block;
  }

  .business_name {
    font-size: 12pt;
    color: #888;

    svg {
      margin-right: 10px;
    }
  }

  .total_hours {
    font-size: 26pt;
    font-weight: bold;
    margin-top: 10px;
  }

  .project_icons {
    padding-top: 8px;
    margin-right: 15px;

    svg {
      margin: 0px 6px;
      color: #ccc;
      font-size: 10pt;

      border-radius: 20px;
      border: 2px solid #ccc;
      padding: 10px;
      width: 40px;
      height: 40px;
    }

    .complete {
      color: green;
      background-color: lightgreen;
      border: 2px solid green;
    }
  }
}

.project_business_subline {
  color: #999;
  font-size: 14pt;
}

.project_hours_row_tight_padding div {
  padding-left: 2px;
  padding-right: 2px;
}

.project_action_log_entries {
  .date {
    color: #999;
    text-align: center;
    font-size: 9pt;
    padding-top: 10px;
  }

  .month {
    font-weight: bold;
    font-size: 11pt;
    display: block;
  }

  .log {
    padding: 10px;
  }

  .hours {
    font-size: 24px;
    color: #aaa;
    font-weight: bold;
    padding-bottom: 25px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .selected_row {
    background-color: #e6ebf1;
  }

  .approved {
    color: #03ab2a;
  }

  .disapproved {
    color: #b92c28;
    opacity: 0.6;
  }

  .functional_icons {    
    font-size: 24px;
    margin-left: 20px;
  }

  .btn_actionLogEdit,
  .btn_actionLogRemove {
    opacity: 0;    
  }

  .project_action_log_entries_row:hover {
    .btn_actionLogEdit,
    .btn_actionLogRemove {
      opacity: 1;
      transition: opacity 0.5s;
    }

    .btn_actionLogEdit:hover {
      cursor: pointer;
      color: theme-color("primary");
    }

    .btn_actionLogRemove:hover {
      cursor: pointer;
      color: theme-color("danger")
    }
  }  
}

.masquerade_icon {
  color: #fff;
  font-size: 36pt;
  padding: 5px 20px;
  background-color: #b33232;
  cursor: pointer;
}

.search-filters {
  font-size: 9pt;
  margin-bottom: 20px;
  margin-top: -10px;

  label {
    margin-bottom: 0.2rem;
    font-size: 11pt;
  }

  select {
    font-size: 9pt;
  }

  .form-control {
    padding: 1.5rem 0.75rem;
  }
}

.table-actions {
  button {
    font-size: 12pt;
    margin-right: 10px;
  }
}

.filter-on {
  display: flex;
  // justify-content: space-between;
  background-color: #fff;
  padding: 20px 10px 0px 10px;
  margin-bottom: 10px;

  .react-select-container {
    // margin: 30px 0 0 0;
    flex-grow: 1;
    margin: 0 0 15px 0;
    min-width: 30%;
    max-width: 35%;

    &:not(:first-of-type) {
      margin-left: 5%;
    }

    > div:first-of-type {
      margin: 0;
    }

    position: relative;

    &.error {
      border-color: theme-color("error");

      label {
        color: theme-color("error");
      }

      & div {
        border-color: theme-color("error") !important;
      }
    }

    label {
      font-size: 12pt; //8
      color: #757575;
      position: absolute;
      top: 11px; //21
      left: 7px;
      z-index: 10;
      display: inline-block;
      background-color: #fff;
      padding: 6px;

      &.small-label {
        transition: all 0.15s;
        font-size: 8pt;
        top: -14px;
      }

      &.big-label {
        transition: all 0.15s;
        font-size: 12pt;
        top: 11px;
      }
    }
  }
}

.big-select {
  div {
    padding: 12px;
  }
}

.table-hover .table_icons {
  opacity: 0.2;
}

.table-hover tr:hover .table_icons {
  opacity: 1;
}

.table_icons {
  font-size: 18pt;
  margin-right: 5px;
  color: theme-color("primary");
  cursor: pointer;

  padding: 10px;
  border-radius: 50%;
  background-color: transparent;

  &:hover {
    background-color: theme-color("light-grey");
  }

  > .disabled {
    color: theme-color("light-grey");
    cursor: default;
  }

  .danger {
    color: theme-color("danger");
  }
}

.avatar {
  width: 40px;
  height: 40px;
  font-size: 20pt;
  color: #fafafa;
  background-color: #20416c;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  line-height: 1;
  border-radius: 50%;
  justify-content: center;
}

.avatar-list-table {
  td {
    vertical-align: middle;
  }
}

.no_results {
  .react-autosuggest__suggestions-list {
    display: none;
  }
  .react-autosuggest__suggestions-container {
    display: none !important;
  }
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 350px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 45px;
  width: 350px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--focused {
  background-color: #0c7eaf;
  color: #fff;
}

.sl_data_table {
  th {
    color: #768192;
    background-color: #d8dbe0;
    border-color: #d8dbe0;
  }
}

.project_jump_icons {
  .delete_icon {
    margin-left: 15px;
  }

  button {
    margin-right: 10px;
    min-width: 40px;
  }

  .btn-light {
    background-color: #f9f9fa;
  }

  svg {
    font-size: 20pt;
    padding: 2px;
  }
}

.contact_form_autocomplete_restyle {
  input {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    width: 100%;
  }

  .react-autosuggest__container--open
    .react-autosuggest__suggestions-container {
    top: 35px;
    width: 100%;
    font-size: 11pt;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #eee;
  }
}

.report_filter_box {
  background-color: #fff;
  padding: 10px 25px;
}

.unapproved_report_header_legend {
  font-size: 14pt;

  .unapproved_hours {
    color: #ccc;
  }

  .accepted {
    color: #03ab2a;
  }

  .unaccepted {
    color: #b92c28;
  }
}

.unapproved_hours_block {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 5px;

  .project_date {
    color: #999;
    text-align: center;
    font-size: 10px;
    margin-top: 10px;
  }

  .project_date .month {
    font-weight: bold;
    font-size: 14px;
    display: block;
  }

  .project_title {
    font-size: 16pt;
  }

  .business_name {
    font-size: 12pt;
    color: #888;
  }

  .project_icons {
    padding-top: 10px;
    font-size: 18pt;
  }

  .project_icons i,
  .project_icons span {
    margin: 0px 12px;
  }

  .unapproved_hours {
    color: #ccc;
  }

  .accepted {
    color: #03ab2a;
  }

  .unaccepted {
    color: #b92c28;
  }
}

.business_timeslot_hours {
  font-size: 18pt;
  .hours span {
    margin: 0px 12px;
  }

  .unapproved_hours {
    color: #ccc;
  }

  .accepted {
    color: #03ab2a;
  }

  .unaccepted {
    color: #b92c28;
  }
}

.project_view_total_hours {
  font-size: 30pt;
  color: #555;
  margin-top: -35px;
}

.hour_approval_icons {
  font-size: 20pt;
  color: #ccc;

  svg {
    cursor: pointer;
    margin: 0px 6px;
  }

  .approve:hover {
    color: #00de42 !important;
  }

  .disapprove:hover {
    color: #ff6868 !important;
  }

  .reset:hover {
    color: #083878 !important
  }
}

.project_info_cards {
  font-size: 9pt;
}

.preparation_question_text {
  font-weight: 600;
  margin-bottom: 10px;
}

.business_view_timeslot_project_listing {
  .card:hover {
    background-color: #f3f4f5;
    cursor: pointer;
  }

  .card-body {
    padding: 1rem !important;
  }

  .student_info {
    font-size: 16pt;
  }

  .student_school {
    font-size: 12pt;
  }

  .student_hours {
    text-align: center;
    font-size: 30pt;
    margin-top: -5px;
  }

  .complete {
    color: #00de42;
  }
}

.student_dashboard_hours {
  font-size: 20pt;
  color: #888;
}

.student_dashboard_text {
  font-size: 12pt;
  line-height: 34pt;
  color: #888;
}

.student_dashboard_hours_container {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 30px;

  .jumbo_text {
    font-size: 40pt;
    color: #333;
  }

  .resume_link {
    font-size: 14pt;
    text-align: center;
  }
}

.dashboard_tiny_text {
  font-size: 10pt;
  color: #555;
  margin-bottom: 10px;
  font-style: italic;
}

.calendar_opportunity_list {
  .opportunity_item {
    margin-bottom: 25px;
  }

  .opportunity_title {
    font-size: 12pt;
  }

  .timeslot_name {
    font-size: 10pt;
    color: #555;
  }

  .expiry_notice {
    color: red;
    font-size: 11pt;
  }

  .registered_count {
    font-size: 10pt;
    color: #555;
  }

  .roster_link {
    font-size: 25pt;
  }
}

.timeslot_printer_icon {
  font-size: 25pt;
}

.print_roster_header_row {
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
  margin: 10px 0;
  font-family: "Open Sans", Arial;
}

.print_roster_listing {
  font-size: 10px;
  font-family: "Open Sans", Arial;
  color: #000;

  .student_name {
    font-size: 14px;
    font-weight: bold;
  }

  .signature {
    border-bottom: 1px solid #000;
    margin: 0px 5px;
    padding: 0px;
    width: 15%;
    flex: none;
  }

  .roster-row:nth-child(even) {
    background: #d3d5d7;
  }

  .roster-row {
    margin: 18px 0;
    padding: 6px 0;
  }
}

@media print {
  .c-header {
    display: none;
  }

  .c-footer {
    display: none;
  }

  .hide-from-print {
    display: none;
  }
}

.capitalize_it {
  text-transform: capitalize;
}

.checkpoint_option {
  margin: 0px 80px;
  text-align: center;
}

.main_option svg:hover {
  background-color: #3c4b64;
  cursor: pointer;
  color: white !important;
}

.main_option svg {
  font-size: 60pt;
  border: 1px solid black;
  border-radius: 15px;
  width: 150px !important;
  height: 100px;
  padding: 10px;
  text-align: center;
  line-height: 65pt;
}

.fa-stack {
  display: inline-block !important;
}

.mini-sync {
  height: 0.8em !important;
  margin-top: 12px;
}

.big_trophy {
  font-size: 24pt;
}

.bronze {
  color: #ca9b4c !important;
}
.silver {
  color: #bbb !important;
}
.gold {
  color: #dad97c !important;
}
.platinum {
  color: #555 !important;
}

.spotlight_school_card {
  width: 425px;
  margin: 10px 10px;
  min-height: 435px;
}

.spotlight_school_logo {
  height: 100px;
  margin-bottom: 10px;
  background-color: #ccc;
  border: 2px solid #555;
}

.Annapolis_Evening_High_School,
.Annapolis_High {
  background-image: url("../assets/img/school_logos/school_1.png");
  background-position: 0px -145px;
  background-size: 400px;
}

.Arundel_High {
  background-image: url("../assets/img/school_logos/school_2.png");
  background-position: 45px -102px;
  background-size: 300px;
}

.Broadneck_High {
  background-image: url("../assets/img/school_logos/school_3.png");
  background-position: 25px -96px;
  background-size: 320px;
}

.Center_of_Applied_Technology_North {
  background-image: url("../assets/img/school_logos/cat_north.png");
  background-position: 25px -40px;
  background-size: 300px;
}

.Center_of_Applied_Technology_South {
  background-image: url("../assets/img/school_logos/cat_south.png");
  background-position: 25px -43px;
  background-size: 300px;
}

.Chesapeake_Science_Point {
  background-image: url("../assets/img/school_logos/chesapeake_science.png");
  background-size: 375px;
  background-position: 0px -60px;
}

.Chesapeake_High {
  background-image: url("../assets/img/school_logos/school_4.png");
  background-size: 230px;
  background-position: 80px -70px;
}

.Crofton_High {
  background-image: url("../assets/img/school_logos/school_13.png");
  background-size: 275px;
  background-position: 50px -80px;
}

.Glen_Burnie_Evening_High_School,
.Glen_Burnie_High {
  background-image: url("../assets/img/school_logos/school_5.png");
  background-size: 260px;
  background-position: 60px -75px;
}

.Meade_Evening_High_School,
.Meade_High {
  background-image: url("../assets/img/school_logos/school_6.png");
  background-position: 30px -50px;
  background-size: 325px;
}

.North_County_Evening_High_School,
.North_County_High {
  background-image: url("../assets/img/school_logos/school_7.png");
  background-position: 40px -110px;
  background-size: 300px;
}

.Northeast_High {
  background-image: url("../assets/img/school_logos/school_8.png");
  background-position: 10px -115px;
  background-size: 350px;
}

.Old_Mill_High {
  background-image: url("../assets/img/school_logos/school_9.png");
  background-position: 40px -110px;
  background-size: 300px;
}

.Phoenix_Academy {
  background-image: url("../assets/img/school_logos/phoenix.png");
  background-position: -30px -90px;
  background-size: 450px;
}

.Severna_Park_High {
  background-image: url("../assets/img/school_logos/school_10.png");
  background-position: 50px -75px;
  background-size: 280px;
}

.Severn_Run_High {
  background-image: url("../assets/img/school_logos/school_14.png");
  background-position: 30px -110px;
  background-size: 300px;
}

.South_River_Evening_High_School,
.South_River_High {
  background-image: url("../assets/img/school_logos/school_11.png");
  background-position: 20px -115px;
  background-size: 350px;
}

.Southern_High {
  background-image: url("../assets/img/school_logos/school_12.png");
  background-position: -10px -50px;
  background-size: 410px;
}

.Virtual_Academy {
  background-image: url("../assets/img/school_logos/virtual_academy.png");
  background-position: 60px -12px;
  background-size: 240px;
}

.no_content_msg {
  background-color: #fff;
  padding: 10px;
  margin-top: 20px;
  min-height: 100px;
}

.timeslot_audience_student_list {
  padding: 5px 0px;
  border-bottom: 1px solid #eee;
}

.timeslot_audience_student_list:last-of-type {
  border-bottom: 0px;
}

.student_resume {
  font-family: "Open Sans", Arial;
  color: #000;

  .business-name {
    font-weight: bold;
    color: #005488;
    font-size: 13pt;
    margin-top: 8px;
  }

  .opp-title {
    font-weight: lighter;
    font-size: 12pt;
  }

  .opp-hours {
    font-size: 22pt;
    font-weight: lighter;
  }

  .opp-dates {
    margin-top: 6px;
  }

  .date-header {
    font-size: 18pt;
    text-decoration: underline;
    margin-bottom: 12px;
    text-align: center;
    color: #000;
  }

  .resume_one_liner {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.actions_table_btns button {
  margin-right: 15px;
}

.actions_table_btns button:last-of-type {
  margin-right: 0px;
}

.fatButton {
  margin: 0px 6px 0px 25px;
  border-radius: 25px;
  padding: 10px;
  width: 95px;
  height: 75px;
  border: 2px solid green;
  display: inline-block;
  position: relative;
  top: -18px;
  text-align: center;
  font-size: 12pt;
  cursor: pointer;
}

.file_assoc_item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 5px 10px;
  cursor: pointer;
  float: left;
  width: 350px;
  font-size: 12pt;

  .date_line {
    font-size: 9pt;
  }
}

.file_assoc_item.selected {
  border-left: 10px solid #083878;

  svg {
    float: right;
    display: block;
    font-size: 24pt;
    color: #083878;
    opacity: 0.5;
    margin-top: 4px;
  }
}

.notification-box {
  color: #151515;
  text-align: center;
  padding: 7px;
  margin-top: -15px;

  svg {
    font-size: 16pt;
    margin-right: 10px;
  }

  &.warning {
    border: 1px solid rgba(252, 76, 2);
    background: rgba(252, 76, 2, 0.5);
  }
}

.approve_badge {
  font-size: 12pt;
  background-color: rgb(3, 171, 42, 0.2);
  border: 2px solid #03ab2a;
  margin-right: 10px;
  padding: 5px 15px;
  color: #333;
}

.search_icons_container {
  font-size: 14pt;
  opacity: 1;

  svg {
    font-size: 20pt;
    cursor: pointer;
  }

  svg:first-of-type {
    margin-right: 18px;
    margin-left: 10px;
  }

  .disabled {
    opacity: .25;
  }
}

.search_calendar_no_limit {
  // margin-top: 10px;
  font-size: 10pt;
  // font-weight: bold;
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  padding: 14px;
  color: #b9b9b9;
}

.fy_hours_report {
  display: flex;
  margin-bottom: 5px;

  .colA {
    flex: 0 0 150px;
    font-weight: bold;
  }

}

.admin_action_button {
  font-size: 12pt;
  border: 2px solid #3c4b64;
  border-radius: 10px;
  padding: 10px;
  width: 150px;
  height: 150px;
  text-align: center;
  align-items: center;
  display: flex;
  flex-flow: wrap;
  cursor: pointer;

  svg {
    font-size: 50pt;
  }

  .break {
    flex-basis: 100%;
    width: 100%;
    height: 0;
  }

  div {
    flex-grow: 1;
  }
}

.resume_inline_image {
  max-height: 300px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.action_log_inline_image {
  max-height: 150px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.move-badge {
  width: 150px;
}


.fa-stack[data-count]:after{
  position:absolute;
  right:0%;
  top:1%;
  content: attr(data-count);
  font-size:30%;
  padding:.6em;
  border-radius:999px;
  line-height:.75em;
  color: white;
  background:#ccc;
  border: #999;
  text-align:center;
  min-width:2em;
  font-weight:bold;
}

.fa-stack[data-count]:hover:after{
    cursor: pointer;
    background: rgba(107, 2, 2, 0.5) !important;
}

.log-image-container {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .fa-stack {
    height: auto !important;
  }
}

.content_loader {
  font-size: 75pt;
  text-align: center;
  color: #005488;

  h3 {
    font-size: 30pt;
    color: theme-color("primary");
  }
}

.profile_top_icons {
  text-align: right;
  font-size: 82px;

  span {
    font-size: 42pt !important;
  }
}

.alertBoxWithIcon {
  display: flex;
  align-items: center;

  svg {
    font-size: 24pt;
    margin-right: 15px;
  }
}

.pies {
  display: flex;
  flex-wrap: wrap;

  div {
    width: 250px;
  }
}
